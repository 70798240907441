import React, { useState } from "react";

import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import GtmService from "../service/gtmService";

import { ContentWrapper, H2 } from "../assets/styles/base";
import { breakpoints } from "../constants/breakpoints";
import { colors } from "../constants/colors";

const CostsInfoBar = () => {
    const handleModalOpen = () => {
        document.body.classList.add("modal-open");

        setShowInfoBar(true);

        GtmService.instance.trackEvent({
            event: "CostsInfoModalOpen",
        });
    };

    const handleModalClose = () => {
        document.body.classList.remove("modal-open");
        setShowInfoBar(false);
    };

    const [showInfoBar, setShowInfoBar] = useState(false);

    return (
        <Wrapper>
            Die beste Finanzierung durch Vergleich - ohne Zusatzkosten.{" "}
            <TextLink onClick={handleModalOpen}>Wie&nbsp;das&nbsp;funktioniert?</TextLink>
            <CSSTransition in={showInfoBar} timeout={1300} unmountOnExit>
                <ModalWrapper onClick={handleModalClose}>
                    <Modal onClick={(e) => e.stopPropagation()}>
                        <Header>
                            Wie das funktioniert?
                            <CloseBtn onClick={handleModalClose}>&#x2715;</CloseBtn>
                        </Header>
                        <p>
                            Alle unsere Beratungsleistungen sind für Dich <strong>kostenlos!</strong> Erst bei
                            erfolgreicher Vermittlung deiner Wunschfinanzierung kommt es zu einer Vergütung,{" "}
                            <strong>welche wir direkt mit der Bank abrechnen</strong>, wodurch keine Zusatzkosten für
                            dich entstehen.
                        </p>
                    </Modal>
                </ModalWrapper>
            </CSSTransition>
        </Wrapper>
    );
};

const modalPadding = "60px";

const Wrapper = styled(ContentWrapper)`
    background: #fff;
    color: #6b7280;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    padding-bottom: 16px;
    padding-top: 16px;
`;

const TextLink = styled.span`
    color: ${colors.primary};
    cursor: pointer;
    text-decoration: underline;
`;

const ModalWrapper = styled.div`
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    transition: opacity ease-out 300ms;

    p {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.4;

        &:last-child {
            margin-bottom: 0;
        }
    }
    strong {
        color: #444;
    }

    > div {
        opacity: 1;
        margin-bottom: ${modalPadding};
        margin-top: ${modalPadding};
        transition: margin-top ease-out 300ms;
    }
    &.enter {
        opacity: 0;

        > div {
            margin-top: 0;
        }
    }
    &.enter-active {
        opacity: 1;

        > div {
            margin-top: ${modalPadding};
        }
    }
    &.exit {
        opacity: 1;

        > div {
            margin-top: ${modalPadding};
        }
    }
    &.exit-active {
        opacity: 0;

        > div {
            margin-top: 0;
        }
    }
`;

const Modal = styled.div`
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    max-width: 460px;
    padding: 30px 20px;
    width: 90%;

    @media (min-width: ${breakpoints.desktopMin}) {
        max-width: 520px;
        padding: 40px 30px;
    }
`;

const Header = styled(H2)`
    align-items: center;
    display: flex;
`;

const CloseBtn = styled.span`
    cursor: pointer;
    font-size: 1.1em;
    margin-left: auto;
`;

export default CostsInfoBar;
