import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Cooperation from "../components/Cooperation";
import CostsInfoBar from "../components/CostsInfoBar";
import Faq from "../components/Faq";
import HomeHeader from "../components/HomeHeader/index";
import Layout from "../components/Layout";
import MediaLogos from "../components/MediaLogos";
import ProcessExplanationShort from "../components/ProcessExplanationShort";
import ProductInCards from "../components/ProductInCards";
import ProductExplainer from "../components/sections/ProductExplainer";
import BreadcrumbList from "../components/seo/BreadcrumbList";
import LocalBusinessStructuredData from "../components/seo/LocalBusinessStructuredData";
import LogoStructuredData from "../components/seo/LogoStructuredData";
import MetaComponents from "../components/seo/MetaComponents";
import PromoteStories from "../components/successStories/PromoteStories";
import TipsAndTricks from "../components/tipsAndTricks/PromoteTips";
import { extendUrlWithSourceVersion, urls } from "../constants/urls";

const Home = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents
                title={t("page.meta.title")}
                description={t("page.meta.description")}
                canonical={{
                    href: "/",
                }}
            />
            <CostsInfoBar />
            <HomeHeader />
            <ProductExplainer />
            <Cooperation hasMarginTop />
            <ProductInCards hasMarginTop theme="dark" CTAUrl={extendUrlWithSourceVersion(urls.survey)} />
            <ProcessExplanationShort hasMarginTop />
            <MediaLogos hasMarginTop hasGreyBg />
            <TipsAndTricks hasMarginTop />
            <PromoteStories hasGreyBg hasMarginTop />
            <Faq hasMarginTop ctaText="Mehr Antworten" ctaUrl="/faq" />
            <BreadcrumbList page={"index"}></BreadcrumbList>
            <LogoStructuredData siteUrl={process.env.GATSBY_SITE_URL} />
            <LocalBusinessStructuredData />
        </Layout>
    );
};

export default Home;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "homeHeader", "page.index", "indexFaq"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
